.brands-hero-image-style {
  object-fit: cover;
  height: 30vh;
  width: 100%;
}

.brands-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  padding: 10px 0;
  margin: 2% 5%;
}

.brands-container-wrapper {
  margin: 2% 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (min-width: 900px) {
  .brands-hero-image-style {
    height: 15vh;
  }

  .brands-container-wrapper {
    margin: 2% 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
